







import { EventBus, SafioEvents } from '@/services/event-bus';
import WorksheetService from '@/services/worksheet-service';
import { Component, Vue } from 'vue-property-decorator';
import PageHeader from '../../components/PageHeader.vue';

@Component({
  components: {
    PageHeader
  },
})
export default class App extends Vue {
  permissions: any[]

  created() {
    EventBus.$on(SafioEvents.userLoaded, this.onUserLoaded)
    location.href = 'worksheet.html'
  }

  beforeDestroy() {
    EventBus.$off(SafioEvents.userLoaded, this.onUserLoaded)
  }

  private async onUserLoaded() {
    const rules = await WorksheetService.getPermissionRules()
    console.log(rules)
  }
}
