import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import '../../util/filters'
import { Auth0Plugin } from '../../auth'
import { domain, clientId, audience } from '../../../auth0.config.json'
import { BootstrapVue } from 'bootstrap-vue'
import '../../styles/main.scss'
import '../../util/filters'
import AuthorizationService from '@/services/authorization-service'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  // eslint-disable-next-line
  onRedirectCallback: (appState: any) => {
    window.location.pathname = '/worksheet.html'
    // router.push(
    //   appState && appState.targetUrl
    //     ? appState.targetUrl
    //     : window.location.pathname
    // )
  }
})

AuthorizationService.registerAbility()

new Vue({
  render: h => h(App),
}).$mount('#app')
